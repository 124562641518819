<template>
    <div class="mx-3">
      <h2 class="px-5 py-5">{{ $t('Digital Wallet Report') }}</h2>
      <v-row class="px-10 py-1">
        <v-col md="3">
          <v-text-field :dark="$store.state.isDarkMode" :label="$t('hr.time.from-date')" type="date"
            v-model="filters.from" clearable>
          </v-text-field>
        </v-col>
        <v-col md="3">
          <v-text-field :dark="$store.state.isDarkMode" :label="$t('hr.time.to-date')" type="date" v-model="filters.to"
            clearable>
          </v-text-field>
        </v-col>
        <v-col md="3">
          <v-text-field :dark="$store.state.isDarkMode" :label="$t('card number')" type="number" v-model="filters.serial" clearable>
          </v-text-field>
        </v-col>
        <v-col md="3">
          <v-autocomplete :dark="$store.state.isDarkMode" color="#757575" :label="$t('type')" :items="type"
            item-text="name" item-value="value" v-model="filters.type"></v-autocomplete>
        </v-col>
        <v-col md="3">
          <AutoCompleteField end-point="/user/auto-complete" :label="$t('user name')" v-model="filters.user_id" />
        </v-col>
        <v-col md="3">
          <AutoCompleteField end-point="/user/auto-complete" :label="$t('Owner Card')" v-model="filters.owner_id" />
        </v-col>
        <v-col md="3">
        </v-col>
        <v-col md="3">
        </v-col>
        <v-col md="3" class="mb-3 py-0">
          <v-btn color="green" dark block @click="getAll(1)">{{ $t('search') }}</v-btn>
        </v-col>
        <v-col md="3" class="mb-3 py-0">
          <v-btn color="red" dark block @click="clearFilter">{{ $t('Delete Search') }}</v-btn>
        </v-col>
        <v-col md="3" class="mb-3 py-0">
          <ExportExcel end-point="/digital-wallet-record/export" :params="filters" />
        </v-col>
      </v-row>
      <v-container id="regular-tables" fluid tag="section">
        <v-simple-table :dark="$store.state.isDarkMode">
          <thead class="table-heading" v-if="!loading">
            <tr>
              <th v-for="(name, i) in tableHeaders" :key="i">
                {{ $t(name) }}
              </th>
            </tr>
          </thead>
          <div class="table__spinnerWrapper" v-if="loading">
            <app-spinner></app-spinner>
          </div>
          <v-fade-transition mode="out-in" :group="true" tag="tbody" v-if="!loading">
            <template>
              <tr v-for="(item, idx) in tableItems" :key="idx">
                <td>{{ item.serial }}</td>
                <td>{{ item.date }}</td>
                <td>{{ item.owner }}</td>
                <td>{{ item.user.name }}</td>
                <td>{{ item.type }}</td>
                <td>{{ item.amount }}</td>
                <td>{{ item.desc }}</td>
              </tr>
            </template>
          </v-fade-transition>
        </v-simple-table>
        <div class="d-flex justify-end mt-5">
          <v-row>
            <v-col md="5 ">
              <v-switch @change="goToAll" v-model="is_all" color="green" :label="$t('Show All')"></v-switch>
            </v-col>
            <app-pagination v-if="(!loading & (paginated))" :disable-pagination="!paginated" :totalPages="total"
              :page="page" @PaginationValue="applyPagination($event)"></app-pagination>
          </v-row>
        </div>
      </v-container>
    </div>
  </template>
  <script>
  import axios from 'axios'
  import spinner from "../../../../components/base/spinner.vue";
  import pagination from "../../../../components/base/pagination.vue";
  import ExportExcel from "../../components/core/ExportExcel.vue";
  import AutoCompleteField from "../../components/core/AutoCompleteField.vue";

  export default {
    components: {
      appSpinner: spinner,
      appPagination: pagination,
      ExportExcel,
      AutoCompleteField,
    },
    data: () => ({
      users: [],
      usersLoading: false,
      is_all: false,
      paginated: true,
      grades: null,
      gradesLoading: false,
      eduClasses: null,
      eduClassesLoading: false,
      childrens: null,
      childrensLoading: false,
      tableItems: [],
      loading: false,
      page: 1,
      total: 1,
      tableHeaders: ["card number", "date","Owner Card","user name", "type", "value",  "description"],
      items: [],
      type: [
        {
          name: "شحن",
          value: "charge",
        },
        {
          name: "سحب",
          value: "pull",
        },
        {
          name: "شراء",
          value: "buy",
        },
        {
          name: "إرجاع",
          value: "return",
        },
      ],
      filters: {
        owner_id: null,
        serial: null,
        user_id: null,
        from: null,
        to: null,
        type: null,
      },
    }),
  
    methods: {
      goToAll() {
        if (this.is_all) {
          this.paginated = false;
        } else { this.paginated = true; }
        this.getAll();
      },
      clearFilter() {
        this.filters.owner_id = undefined;
        this.filters.from = undefined;
        this.filters.to = undefined;
        this.filters.serial = undefined;
        this.filters.user_id = undefined;
        this.filters.type = undefined;
        this.page = 1;
        this.getAll();
      },
      async getAll() {
        try {
          const filterTemp = {};
          Object.keys(this.filters).forEach((key) => {
            if (this.filters[key]) filterTemp[key] = this.filters[key];
          });
          this.loading = true;
          const res = await axios.get("/digital-wallet-record/report", {
            params: {
              size: 10,
              page: this.page,
              paginated: this.paginated,
              ...filterTemp,
            },
          });
          const arr = Array.from(res.data.data)
          if (Array.isArray(arr)) {
            console.log(arr);
            this.tableItems = arr.map((el) => {
              return {
                ...el,
              };
            });
          }
          this.total = res.data.meta.total;
          this.page = res.data.meta.current_page;
        } finally {
          this.loading = false;
        }
      },
      applyPagination(paginationValue) {
        this.page = paginationValue;
        this.getAll();
      },
    },
    async created() {
      this.getAll();
    },
  };
  </script>
  